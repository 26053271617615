import "./src/styles/global.css"

import Layout from "@root/Layout"
import React from "react"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  let TRANSITION_DELAY = 500
  if (location.action === "PUSH") {
    window.setTimeout(
      () =>
        window.scroll({
          left: 0,
          top: 0,
        }),
      TRANSITION_DELAY
    )
  } else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
