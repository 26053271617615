import React, { createContext, useContext, useReducer } from 'react'
import { useMediaQuery } from "react-responsive"

const initialState = {
    mobileMenuOpen: false,
    contactModalOpen: false,
    navbarHovered: false,
    isMobile: null
}

const actions = {
    SET_MOBILE_MENU_OPEN: 'SET_MOBILE_MENU_OPEN',
    SET_SHOW_CONTACT_MODAL: 'SET_SHOW_CONTACT_MODAL',
    SET_NAVBAR_HOVERED: 'SET_NAVBAR_HOVERED',
}

const stateReducer = (state, payload) => {
    switch (payload.action) {
        case actions.SET_MOBILE_MENU_OPEN:
            return {...state, mobileMenuOpen: payload.value}
        case actions.SET_SHOW_CONTACT_MODAL:
            return {...state, contactModalOpen: payload.value}
        case actions.SET_NAVBAR_HOVERED:
            return {...state, navbarHovered: payload.value}
        default:
            return state
    }
}

export const StateContext = createContext({})

export const StateContextProvider = ({ children }) => {

    // const isMobile = useMediaQuery({ maxWidth: 767 })

    const [state, dispatch] = useReducer(
        stateReducer,
        initialState
    );

    const StateContextStore = {
        navbarHovered: state.navbarHovered,
        setNavbarHovered: () =>  dispatch({action: actions.SET_NAVBAR_HOVERED, value: true}),
        unsetNavbarHovered: () =>  dispatch({action: actions.SET_NAVBAR_HOVERED, value: false}),
        mobileMenuOpen: state.mobileMenuOpen,
        openMobileMenu: () =>  dispatch({action: actions.SET_MOBILE_MENU_OPEN, value: true}),
        closeMobileMenu: () =>  dispatch({action: actions.SET_MOBILE_MENU_OPEN, value: false}),
        contactModalOpen: state.contactModalOpen,
        openContactModal: () =>  dispatch({action: actions.SET_SHOW_CONTACT_MODAL, value: true}),
        closeContactModal: () =>  dispatch({ action: actions.SET_SHOW_CONTACT_MODAL, value: false }),
        isMobile: useMediaQuery({ maxWidth: 767 }),
        dispatch,
    }

    return <StateContext.Provider value={StateContextStore}>{children}</StateContext.Provider>
}

export const useStateContext = () => useContext(StateContext)