import React, { useState, useRef } from "react"
import tw, { styled } from "twin.macro"
import Button from "@elements/Button"
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import { useStateContext } from "@context/stateContext"
import { AnimatePresence, motion } from "framer-motion"

const Form = styled(motion.form)`
  ${tw`relative z-10 flex flex-col w-full col-span-12 col-start-1 mt-c32 md:mt-c60 sm:col-span-7 md:col-span-6 md:col-start-2 xl:col-start-4`}

  .hp-button {
    ${tw`ml-auto`}
  }
`
const InputWrap = styled.div`
  ${tw`relative z-10 w-full`}
`

const Label = styled.label`
  ${tw`absolute transition duration-300 ease-in-out transform -left-4 text-lightGrey`}
`
const Input = styled.input`
  ${tw`relative inline-block w-full px-4 py-1 border-b outline-none border-blue text-customGrey font-firacode`}

  &:focus + ${Label},
  &.filled + ${Label} {
    ${tw`-translate-y-2`}
  }
`
const TextAreaWrap = styled.div`
  ${tw`relative w-full mt-c25 md:mt-c40`}

  ${Label} {
    ${tw`absolute top-4`}
  }
`
const TextArea = styled.textarea`
  ${tw`inline-block w-full h-48 p-4 bg-transparent border outline-none border-blue sm:h-80 text-customGrey font-firacode`}

  &:focus + ${Label},
  &.filled + ${Label} {
    ${tw`-translate-y-7`}
  }
`

const CustomCheckbox = styled.span`
  ${tw`relative top-0 left-0 inline-block w-4 h-4 text-white border cursor-pointer border-blue text-30 font-ubuntu`}

  & > span {
    ${tw`absolute transition duration-300 ease-in-out opacity-0 -top-3 -left-1`}
  }
`
const Policy = styled.div`
  ${tw`flex mt-c20`}

  & input:checked + ${CustomCheckbox} {
    span {
      ${tw`text-black opacity-100`}
    }
  }
  & input.policy-error + ${CustomCheckbox} {
    ${tw`border-red-500`}
  }
`

const ContactForm = () => {
  const { closeContactModal } = useStateContext()
  const [formStatus, setFormStatus] = useState({
    state: "pending",
    message: "",
  })
  const form = useRef()

  const formSubmit = event => {
    event.preventDefault()
    setFormStatus({ state: "pending", message: "" })
    const policyBox = document.querySelector("#form_policy")
    policyBox.classList.remove("policy-error")
    if (!policyBox.checked) {
      policyBox.classList.add("policy-error")
      setFormStatus({
        state: "policy-error",
        message: "",
      })
      return
    }
    const formData = new FormData(form.current)
    const dataJson = JSON.stringify(Object.fromEntries(formData))
    fetch(
      "https://sz2yisrz87.execute-api.eu-west-1.amazonaws.com/production/email/send",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: dataJson,
      }
    )
      .then(async response => {
        const data = await response.json()

        if (response.status === 200) {
          setFormStatus({ state: "success", message: data })
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "formSubmission",
            formType: "Contact us",
          })
          return
        } else {
          setFormStatus({ state: "error", message: data })
          return
        }
      })
      .catch(error => setFormStatus({ state: "error", message: "" }))
  }

  return (
    <AnimatePresence exitBeforeEnter>
      {formStatus.state !== "success" && (
        <Form
          initial={{ x: "0%" }}
          animate={{ x: "0%" }}
          exit={{ x: "200%" }}
          ref={form}
        >
          {(formStatus.state === "error" ||
            formStatus.state === "policy-error") && (
            <motion.div
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              className="response error"
            >
              <p className="mb-10 text-sm text-red-400 font-firacode">
                {formStatus.message !== "" &&
                  formStatus.message === `Missing final '@domain'` && (
                    <span>Bitte geben Sie eine gültige E-Mail Adresse!</span>
                  )}
                {formStatus.state === "policy-error" && (
                  <span>
                    Bitte akzeptieren Sie die Datenschutzbestimmungen um das
                    Formular abzuschicken!
                  </span>
                )}
                {formStatus.message !== "" &&
                  formStatus.message ===
                    `Missing parameters! Make sure to add parameters 'email', 'name', 'content'.` && (
                    <span>
                      Bitte füllen Sie alle Felder aus, um das Formular
                      abzusenden.
                    </span>
                  )}
                {formStatus.state !== "policy-error" &&
                  formStatus.message !== "" &&
                  formStatus.message !== `Missing final '@domain'` &&
                  formStatus.message !==
                    `Missing parameters! Make sure to add parameters 'email', 'name', 'content'.` && (
                    <span>
                      Bitte laden sie die Seite neu und versuchen Sie es
                      nocheinmal!
                    </span>
                  )}
              </p>
            </motion.div>
          )}
          <div className="sm:flex">
            <InputWrap className="mb-4 input-wrap sm:mb-0 sm:mr-4">
              <Input
                id="name"
                name="name"
                type="text"
                onChange={e => {
                  e.target.value !== ""
                    ? e.target.classList.add("filled")
                    : e.target.classList.remove("filled")
                }}
              />
              <Label
                htmlFor="name"
                className="symbol-code"
              >{`<Ihr Name>`}</Label>
            </InputWrap>
            <InputWrap className="input-wrap sm:ml-4">
              <Input
                id="email"
                name="email"
                type="email"
                onChange={e => {
                  e.target.value !== ""
                    ? e.target.classList.add("filled")
                    : e.target.classList.remove("filled")
                }}
              />
              <Label
                htmlFor="email"
                className="symbol-code"
              >{`<Ihre E-Mail>`}</Label>
            </InputWrap>
          </div>
          <TextAreaWrap>
            <TextArea
              name="content"
              id="content"
              onChange={e => {
                e.target.value !== ""
                  ? e.target.classList.add("filled")
                  : e.target.classList.remove("filled")
              }}
            />
            <Label
              htmlFor="content"
              className="symbol-code"
            >{`<Ihre Nachricht>`}</Label>
          </TextAreaWrap>
          <Policy>
            <input
              className="absolute z-10 w-4 h-4 border opacity-0 border-blue border-radius-0"
              type="checkbox"
              id="form_policy"
            />
            <CustomCheckbox>
              <span>&#10003;</span>
            </CustomCheckbox>
            <label
              className="w-11/12 ml-4 font-normal font-firacode text-lightGrey text-12"
              htmlFor="form_policy"
            >
              {`//Ich habe die`}{" "}
              <Link
                className="text-blue"
                onClick={() => {
                  navigate("/datenschutz/")
                  closeContactModal()
                }}
                to="/datenschutz/"
              >{`->Datenschutzhinweise`}</Link>{" "}
              gelesen und bin mir über die Art und Weise, wie die Hattenberger
              GmbH Daten erhebt, verbreitet und nutzt, im Klaren.
            </label>
          </Policy>
          {/* <input type="submit" value="Send" /> */}
          <Button
            onClick={formSubmit}
            type="submit"
            buttonText="Jetzt absenden"
            buttonClasses="bg-blue text-white"
          />
        </Form>
      )}
      {formStatus.state === "success" && (
        <motion.div
          initial={{ x: "-200%" }}
          animate={{ x: "0%" }}
          className="relative z-10 flex flex-col w-full col-span-12 col-start-1 pr-10 response thank-you mt-c32 md:mt-c60 sm:col-span-7 md:col-span-6 md:col-start-2 xl:col-start-4"
        >
          <p className="pb-6 text-2xl font-bold text-black font-ubuntu">
            Vielen Dank für Ihre Anfrage!
          </p>
          <p className="text-base text-black font-firacode">
            Wir haben Ihre Nachricht erhalten und werden uns zeitnah bei Ihnen
            melden!
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ContactForm
