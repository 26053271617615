import React, { useEffect } from "react"
import { useStateContext } from "@context/stateContext"
import { motion, AnimatePresence } from "framer-motion"
import tw, { styled } from "twin.macro"
import ContactForm from "@modules/ContactForm"
import parse from "html-react-parser"

const HeadlineWrap = styled.div`
  ${tw`relative z-10 font-black font-ubuntu px-offset sm:px-12 lg:px-16 xl:px-24`}
`
const Grid = styled.div`
  ${tw`relative z-10 grid grid-cols-12 px-offset sm:px-12 md:px-0`}
`
const Info = styled.p`
  ${tw`w-full col-span-12 pt-5 pr-0 text-xs font-normal sm:ml-6 sm:col-start-8 sm:col-span-4 font-firacode sm:text-14 md:text-16 text-customGrey xl:col-span-3 xl:col-start-10 sm:pt-c96 md:pr-8`}
`
const Symbol = styled.span`
  ${tw`absolute z-0 font-firacode text-yellow -right-20 top-1/2 sm:top-1/3 md:top-1/2 text-14xl whitespace-nowrap`}
`

function ContactModal() {
  const { contactModalOpen, closeContactModal } = useStateContext()

  useEffect(() => {
    if (contactModalOpen) {
      document.documentElement.style.overflowY = "hidden"
    }
    if (!contactModalOpen) {
      document.documentElement.style.overflowY = "auto"
    }
  }, [contactModalOpen])

  return (
    <AnimatePresence>
      {contactModalOpen && (
        <motion.div
          role="dialog"
          initial="out"
          animate="in"
          exit="out"
          variants={bgVariants}
          className={`modal-backdrop fixed flex justify-center items-center inset-0 bg-white z-[100] perspectivee`}
        >
          <motion.button
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            onClick={() => closeContactModal()}
            className={`absolute text-blue text-40 md:text-60 z-10 right-8 top-4 focus:outline-none`}
          >
            &#10005;
          </motion.button>
          <motion.div
            className={`w-full h-full bg-white shadow-2xl text-blue pb-4 pt-c80 sm:py-c60 overflow-y-auto overflow-x-hidden`}
            initial="out"
            animate="in"
            exit="out"
            variants={cardVariants}
          >
            <HeadlineWrap>
              <h2 className="relative font-bold headline-card font-ubuntu">
                <span className="relative">
                  Wir freuen uns von Ihnen zu hören
                  <span className="normal-case symbol-code symbol-code__start text-lightGrey">
                    {`<hello>`}
                  </span>
                </span>
              </h2>
            </HeadlineWrap>
            <Symbol>{`->>`}</Symbol>
            <Grid>
              <ContactForm />
              <Info>
                Hattenberger GmbH
                <br />
                Aventinstraße 6<br />
                80469 München
                <br />
                <br />
                T.&nbsp;
                <a href="tel:+49 (0)89 / 41 11 55 55">
                  +49&nbsp;(0)89&nbsp;/&nbsp;41&nbsp;11&nbsp;55&nbsp;55
                </a>
                <br />
                E.&nbsp;
                <a href="mailto:info@hattenbergerpartner.de">
                  info@hattenbergerpartner.de
                </a>
                <span className="hidden sm:block">
                  <br />
                  Sie bekommen eine Rückmeldung innerhalb weniger Stunden, egal
                  um was es geht, z.B. <br />
                  <br />
                  {parse(`// Ein persönliches Gespräch <br />
                // Feedback, was möglich und machbar ist <br />
                // Ein unverbindliches Angebot <br />
                // Infos zur Go Digital Förderung`)}
                </span>
              </Info>
            </Grid>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ContactModal

const bgVariants = {
  out: {
    backgroundColor: "#FFF0",
    transition: { when: "afterChildren" },
  },
  in: {
    backgroundColor: "#FFFF",
    transition: { when: "beforeChildren" },
  },
}
const cardVariants = {
  out: {
    x: "110%",
  },
  in: {
    x: 0,
    transition: {
      type: "tween",
      ease: "circOut",
    },
  },
}
