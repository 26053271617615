import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { motion } from "framer-motion"
import parse from "html-react-parser"

const Button = ({
  buttonStyle,
  buttonClasses,
  buttonText,
  buttonHref,
  onClick,
  buttonTitle,
  buttonTarget = "_self",
}) => {
  // Smooth scroll handler
  const handleSmoothScroll = e => {
    if (buttonHref && buttonHref.startsWith("#")) {
      e.preventDefault()
      const targetElement = document.getElementById(buttonHref.substring(1))
      if (targetElement) {
        const yOffset = -100 // Desired offset top in pixels
        const y =
          targetElement.getBoundingClientRect().top + window.scrollY + yOffset
        window.scrollTo({ top: y, behavior: "smooth" })
      }
    } else if (onClick) {
      onClick(e)
    }
  }

  return (
    <motion.div
      className="flex w-full hp-button pt-c40 md:pt-c32 2xl:pt-c40 xs:w-auto"
      onClick={handleSmoothScroll}
    >
      <motion.span
        className="w-full cursor-pointer xs:w-auto"
        style={{ boxShadow: "4px 4px 0px #000" }}
        initial={{ y: 0 }}
        whileHover={{ y: -1, boxShadow: "5px 5px 1px #000" }}
        whileTap={{ y: 1, boxShadow: "1px 1px 1px #000" }}
      >
        {buttonTarget === "_blank" && (
          <a
            href={buttonHref ?? "/"}
            style={buttonStyle}
            className={buttonClasses}
            target={buttonTarget}
            onClick={handleSmoothScroll}
          >
            {parse(buttonText)}
          </a>
        )}
        {!buttonHref?.includes("mailto:") && buttonTarget !== "_blank" && (
          <Link
            to={buttonHref ?? "/"}
            style={buttonStyle}
            title={buttonTitle ?? buttonText}
            aria-label={buttonTitle ?? buttonText}
            className={`${
              onClick === undefined ? "" : "pointer-events-none"
            } ${buttonClasses}`}
            onClick={handleSmoothScroll}
          >
            {parse(buttonText)}
          </Link>
        )}
        {buttonHref?.includes("mailto:") && (
          <a
            href={buttonHref ?? "/"}
            style={buttonStyle}
            className={buttonClasses}
            onClick={handleSmoothScroll}
          >
            {parse(buttonText)}
          </a>
        )}
      </motion.span>
    </motion.div>
  )
}

Button.propTypes = {
  buttonData: PropTypes.object,
}

export default Button
