import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import DesktopNavigation from "./DesktopNavigation"
import MobileNavigation from "./MobileNavigation"
import { useLocation } from "@reach/router"
import Logo from "@elements/Logo"
import throttle from "lodash-es/debounce"
import { motion } from "framer-motion"
import { useStateContext } from "@context/stateContext"

const isBrowser = () => typeof window !== "undefined"
function NavBar() {
  const { isMobile, setNavbarHovered, unsetNavbarHovered } = useStateContext()

  const location = useLocation()
  const locationPathName = location.pathname

  // const [navBarHovered, setNavBarHovered] = useState()

  const [pageScrolled, setPageScrolled] = useState(
    locationPathName !== "/" ? true : false
  )
  const handelMenuVisibility = event => {
    setPageScrolled(window.scrollY ? true : false)
  }
  useEffect(() => {
    if (locationPathName !== "/") {
      setPageScrolled(true)
    } else {
      setPageScrolled(window.scrollY ? true : false)
    }
    handelMenuVisibility()
  }, [locationPathName])
  const onWindowScroll = throttle(handelMenuVisibility, 50, {
    trailing: true,
    leading: true,
  })
  useEffect(() => {
    isBrowser() && window.addEventListener("scroll", onWindowScroll.bind(this))
    return () =>
      isBrowser() && window.removeEventListener("scroll", onWindowScroll)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const data = useStaticQuery(graphql`
    query MainMenu {
      wpMenu(slug: { eq: "main-menu" }) {
        id
        menuItems {
          nodes {
            label
            path
            id
            parentId
            childItems {
              nodes {
                id
                path
                label
                childItems {
                  nodes {
                    id
                    label
                    path
                    parentId
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  // Filter out all menue items that have a parent item
  const menuItems = data.wpMenu.menuItems.nodes.filter(
    menuItem => menuItem.parentId === null
  )
  return (
    <>
      <div
        className={`z-50 fixed w-full h-[30px] px-offset md:px-offsetMd lg:px-16h-20 xl:px-24 flex justify-end justify-items-center text-base bg-black text-piggyPink font-bold`}
      >
        <a
          className={`flex items-center font-ubuntu`}
          target="_blank"
          rel="noopener"
          href={`https://schmitts-katze.de`}
          title={`Zu den Bannerangeboten`}
          aria-label={`Zu den Bannerangeboten`}
        >
          <span>Zu den Bannerangeboten</span>
          <span className={`font-firacode`}>|-&gt;</span>
        </a>
      </div>
      {typeof window !== "undefined" && isMobile && (
        <div
          className={`z-50 flex justify-between items-center px-offset md:px-offsetMd lg:px-16h-20 xl:px-24 top-[30px] h-20 md:h-24 fixed w-full bg-white 
          ${pageScrolled ? "shadow-xl" : ""} transition-shadow duration-700`}
        >
          <Link
            className="absolute self-start w-20 h-20 md:h-24 xl:h-64 md:w-24 xl:w-64"
            to={`/`}
          >
            <Logo className="w-20 h-20 md:h-24 xl:h-64 md:w-24 xl:w-64" />
          </Link>
          <MobileNavigation menuItems={menuItems} />
        </div>
      )}
      {typeof window !== "undefined" && !isMobile && (
        <div
          onMouseEnter={() => setNavbarHovered()}
          onMouseLeave={() => unsetNavbarHovered()}
          className={`z-40 flex justify-between items-center px-offset md:px-offsetMd lg:px-16h-20 xl:px-24 top-[30px] md:h-20 lg:h-20 fixed w-full ${
            pageScrolled ? "shadow-xl bg-white" : ""
          } transition-shadow transition-colors duration-400`}
        >
          <MotionLink
            className="relative z-10 self-start w-20 h-20 md:h-20 lg:h-20 md:w-20 lg:w-20 origin-logo2030 lg:origin-logo5030 2xl:origin-logo2030"
            to={`/`}
            animate={pageScrolled ? "small" : "big"}
            variants={desktopLogoVariants}
            initial={desktopLogoVariants.big}
            title="Hattenberger Partner"
            transformTemplate={(_, transform) => 
              transform.replace("translateZ(0)", "")
            }
          >
            <Logo className="w-20 h-20 md:h-20 lg:h-20 md:w-20 lg:w-20" />
          </MotionLink>
          <DesktopNavigation
            menuItems={menuItems}
            // navBarHovered={navBarHovered}
          />
        </div>
      )}
    </>
  )
}

export default NavBar

const MotionLink = motion(Link)

const desktopLogoVariants = {
  small: {
    scale: 1,
  },
  big: {
    scale: 3,
  },
}
