import React from 'react'

type LogoProps = {
  color: string,
  className: string
}

const Logo = ( {color = '#000', className}: LogoProps ) => {
  return (
    <svg
    aria-label="site-logo"
      className={className}
      width='512'
      height='512'
      viewBox='0 0 512 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='512' height='512' fill='transparent' />
      <path
        d='M212.003 332H182.431V271.611H127.019V332H97.351V192.082H127.019V246.92H182.431V192.082H212.003V332Z'
        fill={color}
        />
      <path
        d='M356.461 257.926H366.223C375.347 257.926 382.174 256.139 386.704 252.566C391.234 248.93 393.499 243.666 393.499 236.775C393.499 229.821 391.585 224.685 387.756 221.367C383.992 218.049 378.058 216.391 369.956 216.391H356.461V257.926ZM423.454 235.723C423.454 250.78 418.732 262.296 409.29 270.271C399.911 278.247 386.544 282.234 369.19 282.234H356.461V332H326.794V192.082H371.487C388.458 192.082 401.346 195.751 410.151 203.088C419.019 210.361 423.454 221.24 423.454 235.723Z'
        fill={color}
      />
    </svg>
  )
}

export default Logo