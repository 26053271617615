import { AnimatePresence, motion } from "framer-motion"
import { StateContextProvider } from "@context/stateContext"
import StickyContactButton from "@elements/StickyContactButton"
import ContactModal from "@modules/ContactModal"
import NavBar from "@root/NavBar"
import PropTypes from "prop-types"
import React from "react"

const mainVariants = {
  in: {
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.08,
      staggerDirection: 1,
    },
  },
  out: {
    transition: {
      when: "afterChildren",
      staggerChildren: 0.08,
      staggerDirection: -1,
    },
  },
}

const Layout = ({ props, children }) => {
  return (
    <StateContextProvider>
      <NavBar />
      <StickyContactButton />
      <ContactModal />
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.main
          className="relative"
          variants={mainVariants}
          key={`motion-${Math.random(0, 999)}`}
        >
          {children}
          <motion.div
            className="bg-black min-h-screen fixed top-0 left-0 w-full"
            initial={{
              opacity: 0.98,
              zIndex: 99,
              display: "block",
            }}
            animate={{
              opacity: 0,
              transition: {
                duration: 0.5,
                ease: [0.3, 0.01, 0.26, 0.9],
              },
              transitionEnd: {
                zIndex: -1,
                display: "none",
              },
            }}
            exit={{
              opacity: 0.98,
              zIndex: 99,
              display: "block",
              transition: {
                duration: 0.5,
                ease: [0.3, 0.01, 0.26, 0.9],
              },
            }}
          ></motion.div>
        </motion.main>
      </AnimatePresence>
    </StateContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
