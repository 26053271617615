import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import Naviburger from "@elements/Naviburger"
import { useLocation } from "@reach/router"
import tw, { styled } from "twin.macro"
import { useStateContext } from "@context/stateContext"

const bgVariants = {
  closed: {
    x: "100%",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.4,
    },
  },
  open: {
    x: 0,
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.4,
      staggerChildren: 0.05,
    },
  },
}

const liVariants = {
  closed: {
    x: "100%",
    transition: {
      type: "tween",
      ease: "easeIn",
      duration: 0.4,
    },
  },
  open: {
    x: 0,
    transition: {
      type: "tween",
      ease: [0.25, 0.75, 0.4, 1],
      duration: 0.6,
    },
  },
}
const ulVariants = {
  closed: {
    height: 0,
    transition: {
      type: "tween",
      ease: "easeIn",
      duration: 0.2,
    },
  },
  open: {
    height: "auto",
    transition: {
      type: "tween",
      ease: [0.25, 0.75, 0.4, 1],
      duration: 0.2,
    },
  },
}

function MobileNavigation({ menuItems, className }) {
  const location = useLocation()
  const locationPathName = location.pathname

  const { mobileMenuOpen, openMobileMenu, closeMobileMenu } = useStateContext()

  useEffect(() => {
    closeMobileMenu()
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (mobileMenuOpen) {
      document.documentElement.style.overflow = "hidden"
    } else {
      document.documentElement.style.overflow = "hidden auto"
    }
  }, [mobileMenuOpen])

  return (
    <>
      <motion.nav
        initial={bgVariants.closed}
        variants={bgVariants}
        animate={mobileMenuOpen ? "open" : "closed"}
        className={`w-screen h-screen absolute bg-blue top-0 left-0 overflow-y-auto`}
      >
        <div
          className={`absolute pt-24 pb-24 left-10 right-0 pr-20 text-white min-h-full flex flex-col justify-center overflow-x-hidden`}
        >
          <ul className="block relative">
            {menuItems.map(({ id, label, path, childItems }) => (
              <MenuItem
                key={id}
                label={label}
                childItems={childItems}
                path={path}
                locationPathName={locationPathName}
                level={0}
              ></MenuItem>
            ))}
          </ul>
        </div>
      </motion.nav>
      <Naviburger
        className={`relative z-10 ml-auto`}
        menuOpen={mobileMenuOpen}
        onClick={e => (mobileMenuOpen ? closeMobileMenu() : openMobileMenu())}
      />
    </>
  )
}

export default MobileNavigation

const MenuItem = ({
  id,
  label,
  path,
  childItems,
  parentOpen,
  level,
  locationPathName,
}) => {
  if (childItems && childItems.nodes.length > 0) {
    return (
      <SubMenu
        className={`level-${level}`}
        id={id}
        label={label}
        path={path}
        childItems={childItems}
        parentOpen={parentOpen ? parentOpen : "noParrent"}
        level={level}
        locationPathName={locationPathName}
      />
    )
  } else {
    return (
      <MenuListItem
        variants={level === 0 ? liVariants : null}
        level={level}
        active={path === locationPathName}
      >
        <Link className={``} to={path} title={label} aria-label={label}>
          {label}
        </Link>
      </MenuListItem>
    )
  }
}

function SubMenu({
  id,
  label,
  path,
  childItems,
  parentOpen,
  level,
  locationPathName,
}) {
  const [open, setOpen] = useState(false)
  const newLevel = level + 1
  useEffect(() => {
    if (parentOpen !== true) setOpen(false)
  }, [parentOpen])
  useEffect(() => {
    setOpen(false)
  }, [locationPathName])
  const toggleOpen = clickEvent => {
    // clickEvent.stopPropagation()
    if (parentOpen && level === 1) {
      setOpen(false)
    } else {
      setOpen(!open)
    }
  }
  return (
    <MenuListItem
      className={`relative level-${level}`}
      onClick={clickEvent => toggleOpen(clickEvent)}
      level={level}
      variants={level === 0 ? liVariants : null}
    >
      <span>{label}</span>
      <OpenIndicator isOpen={open} level={level} />
      <motion.ul
        initial={ulVariants.closed}
        variants={ulVariants}
        animate={open ? "open" : level === 1 ? "open" : "closed"}
        className={`overflow-hidden ${level} ${level === 0 ? "submenu" : null}`}
      >
        {childItems.nodes.map(({ id, label, path, childItems }) => (
          <MenuItem
            key={id}
            label={label}
            childItems={childItems ? childItems : null}
            path={path}
            parentOpen={open}
            level={newLevel}
            locationPathName={locationPathName}
          ></MenuItem>
        ))}
      </motion.ul>
    </MenuListItem>
  )
}

const OpenIndicator = ({ isOpen, level }) => {
  const variants = {
    open: {
      rotate: -90,
      scale: 0.8,
      x: level !== 1 ? 0 : 1200,
    },
    closed: {
      rotate: 90,
      scale: 0.8,
      x: level !== 1 ? 0 : 1200,
    },
  }
  return (
    <motion.span
      className={`font-firacode absolute right-0 bottom-4 text-2xl xs:text-4xl sm:text-5xl`}
      initial={variants.closed}
      variants={variants}
      animate={isOpen ? "open" : "closed"}
    >
      -&gt;
    </motion.span>
  )
}

const MenuListItem = motion(
  styled.li(({ level, active }) => {
    switch (level) {
      case 0:
        return [
          { textDecoration: active ? "underline" : "none" },
          tw`
            font-ubuntu
            cursor-pointer
            text-white
            font-bold
            text-3xl xs:text-5xl sm:text-6xl
            border-t-2 xs:border-t-4
            last:border-b-2 xs:last:border-b-4
            py-4
            `,
        ]
      case 1:
        return [
          { textDecoration: active ? "underline" : "none" },
          tw`
          font-ubuntu
          text-2xl xs:text-3xl sm:text-4xl
          first-of-type:mt-2 first-of-type:xs:mt-4
          pl-12
          py-1 xs:py-2
          xs:pl-16
          sm:pl-20
          `,
        ]
      case 2:
        return [
          { textDecoration: active ? "underline" : "none" },
          tw`
            font-ubuntu
            text-base xs:text-lg sm:text-xl
            first-of-type:mt-2 first-of-type:xs:mt-3 first-of-type:sm:mt-4
            pl-0
        `,
        ]
      default:
        return [
          { textDecoration: active ? "underline" : "none" },
          tw`
            font-ubuntu
            cursor-pointer
            text-white
            font-bold
            text-3xl xs:text-5xl sm:text-6xl
            border-t-2 xs:border-t-4
            last:border-b-2 xs:last:border-b-4
            py-4
            `,
        ]
    }
  })
)
