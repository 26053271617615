import { Link } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "@reach/router"
import { motion } from "framer-motion"
import tw, { styled } from "twin.macro"
import { useStateContext } from "@context/stateContext"
import { globalHistory } from "@reach/router"

const liVariants = {
  closed: {
    y: "-100%",
    opacity: 0,
    scale: 1,
    transition: {
      type: "tween",
      ease: "easeIn",
      duration: 0.4,
    },
  },
  open: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      type: "tween",
      ease: "circOut",
      duration: 0.25,
    },
  },
}
const ulVariants = {
  closed: {
    transition: {
      type: "tween",
      ease: "easeIn",
      duration: 0.2,
      staggerChildren: 0.05,
    },
  },
  open: {
    transition: {
      type: "tween",
      ease: "circOut",
      duration: 0.2,
      staggerChildren: 0.07,
    },
  },
}

function DesktopNavigation({ menuItems, className }) {
  const location = useLocation()
  const locationPathName = location.pathname
  const [yellowOffset, setYellowOffset] = useState(0)
  const [closedAll, setClosedAll] = useState(false)

  const { navbarHovered, unsetNavbarHovered } = useStateContext()

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") unsetNavbarHovered()
    })
  }, [unsetNavbarHovered])

  useEffect(() => {
    setClosedAll(true)
  }, [navbarHovered])

  return (
    <>
      <motion.div
        className={`desktop-menu-background w-screen h-screen absolute left-0 bg-yellow z-0 bottom-full`}
        initial={{ bottom: "100%", y: 0 }}
        animate={{ y: yellowOffset, bottom: navbarHovered ? "0%" : "100%" }}
        transition={{ type: "tween", ease: "circOut", duration: 0.2 }}
      />
      <nav className={`${className} z-10`}>
        <ul className="m-0 p-0 flex">
          {menuItems.map(({ id, label, path, childItems }) => (
            <MenuItem
              setYellowOffset={setYellowOffset}
              key={id}
              label={label}
              childItems={childItems}
              path={path}
              locationPathName={locationPathName}
              level={0}
              setClosedAll={setClosedAll}
              closedAll={closedAll}
            ></MenuItem>
          ))}
        </ul>
      </nav>
    </>
  )
}

export default DesktopNavigation

const MenuItem = ({
  id,
  label,
  path,
  childItems,
  parentOpen,
  parentRef,
  level,
  locationPathName,
  setYellowOffset,
  setClosedAll,
  closedAll,
}) => {
  if (childItems && childItems.nodes.length > 0) {
    return (
      <SubMenu
        setYellowOffset={setYellowOffset}
        className={`level-${level}`}
        id={id}
        label={label}
        path={path}
        childItems={childItems}
        parentOpen={parentOpen ? parentOpen : "noParrent"}
        parentRef={parentRef ? parentRef : null}
        level={level}
        locationPathName={locationPathName}
        setClosedAll={setClosedAll}
        closedAll={closedAll}
      />
    )
  } else {
    return (
      <MenuListItem
        variants={level !== 0 ? liVariants : null}
        level={level}
        active={path === locationPathName}
        parentOpen={parentOpen ? parentOpen : "noParrent"}
        parentRef={parentRef ? parentRef : null}
        setClosedAll={setClosedAll}
        closedAll={closedAll}
      >
        <Link className={``} to={path} title={label} aria-label={label}>
          {label}
        </Link>
      </MenuListItem>
    )
  }
}

function SubMenu({
  id,
  label,
  path,
  childItems,
  level,
  locationPathName,
  setYellowOffset,
  parentOpen,
  parentRef,
  setClosedAll,
  closedAll,
}) {
  const { setNavbarHovered } = useStateContext()
  const [open, setOpen] = useState(false)
  const newLevel = level + 1
  const ul = useRef(null)
  useEffect(() => {
    if (parentOpen !== true) setOpen(false)
  }, [parentOpen])
  useEffect(() => setOpen(false), [locationPathName])
  useEffect(() => {
    if (closedAll) {
      setOpen(false)
      setClosedAll(false)
    }
  }, [closedAll])
  const toggleOpen = clickEvent => {
    if (level === 0 && !open) {
      setClosedAll(true)
      setTimeout(() => {
        setOpen(true)
        setNavbarHovered()
      }, 10)
    } else if (level > 0 && !open) {
      setOpen(!open)
    } else {
      setOpen(false)
    }

    clickEvent.stopPropagation()
  }
  useEffect(() => {
    const { height } = parentRef
      ? parentRef.current.getBoundingClientRect()
      : ul.current.getBoundingClientRect()
    if (open) {
      setYellowOffset(height)
    } else {
      if (parentRef) {
        const { height } = parentRef.current.getBoundingClientRect()
        setYellowOffset(height)
      } else {
        setYellowOffset(0)
      }
    }
  }, [open])
  return (
    <MenuListItem
      className={`level-${level}`}
      onClick={clickEvent => {
        toggleOpen(clickEvent)
      }}
      onHoverStart={() => level === 0 && setOpen(true)}
      onHoverEnd={() => level === 0 && setOpen(false)}
      level={level}
      variants={level !== 0 ? liVariants : null}
    >
      <span>{label}</span>
      {level === 1 && <OpenIndicator isOpen={open} level={level} />}
      <motion.ul
        ref={ul}
        // initial={ulVariants.closed}
        variants={ulVariants}
        animate={open ? "open" : "closed"}
        className={`${
          level === 0 ? "absolute" : "relative"
        } right-0 overflow-hidden w-36 top-full ${open ? "h-auto" : "h-0"}`}
      >
        {childItems.nodes.map(({ id, label, path, childItems }) => (
          <MenuItem
            setYellowOffset={setYellowOffset}
            key={id}
            label={label}
            childItems={childItems ? childItems : null}
            path={path}
            level={newLevel}
            locationPathName={locationPathName}
            parentOpen={open}
            parentRef={ul}
          ></MenuItem>
        ))}
      </motion.ul>
    </MenuListItem>
  )
}

const OpenIndicator = ({ isOpen, level }) => {
  const variants = {
    open: {
      rotate: -90,
      scale: 0.8,
    },
    closed: {
      rotate: 90,
      scale: 0.8,
    },
  }
  return (
    <motion.span
      className={`font-firacode relative right-0 inline-block`}
      initial={variants.closed}
      variants={variants}
      animate={isOpen ? "open" : "closed"}
    >
      -&gt;
    </motion.span>
  )
}

const MenuListItem = motion(
  styled.li(({ level, active }) => {
    switch (level) {
      case 0:
        return [
          {
            fontWeight: active ? "bold" : "normal",
            transformOrigin: "100% 0",
          },
          tw`
            font-ubuntu
            cursor-pointer
            text-black
            text-base
            uppercase
            ml-3
            p-3
            last-of-type:pr-0
            relative
            `,
        ]
      case 1:
        return [
          {
            fontWeight: active ? "bold" : "normal",
            transformOrigin: "100% 0",
          },
          tw`
              font-ubuntu
              text-base
              uppercase
              pt-3
              first-of-type:pt-0
              pr-3
              text-right
              `,
        ]
      case 2:
        return [
          {
            fontWeight: active ? "bold" : "normal",
            transformOrigin: "100% 0",
          },
          tw`
                font-ubuntu
                text-xs
                normal-case
                pt-2
                pr-3
        `,
        ]
      default:
        return [
          {
            fontWeight: active ? "bold" : "normal",
            transformOrigin: "100% 0",
          },
          tw`
          font-ubuntu
          cursor-pointer
          text-black
          text-base
          uppercase
          ml-3
          p-3
          last-of-type:pr-0
          relative
          `,
        ]
    }
  })
)
